<template>
  <div class="help-chat" :key="$route.params.id">
    <div class="row">
      <div class="top-row w-100 py-2">
        <h3 class="heading-text">Help Center</h3>
        <div class="menu-pill-container shadow-sm">
          <div
            class="menu-pill"
            v-bind:class="chatId === 'customer' ? 'active' : ''"
            @click="changeChat('customer')"
          >
            Customers<span
              class="ml-2 notification-white"
              v-if="notifications.customer >= 1"
            >
              {{ notifications.customer }}</span
            >
          </div>
          <div
            class="menu-pill"
            v-bind:class="chatId === 'rider' ? 'active' : ''"
            @click="changeChat('rider')"
          >
            Baskytor<span
              class="ml-2 notification-white"
              v-if="notifications.rider >= 1"
            >
              {{ notifications.rider }}</span
            >
          </div>
          <div
            class="menu-pill"
            v-bind:class="chatId === 'vendor' ? 'active' : ''"
            @click="changeChat('vendor')"
          >
            Partner<span
              class="ml-2 notification-white"
              v-if="notifications.vendor >= 1"
            >
              {{ notifications.vendor }}</span
            >
          </div>
          <div
            class="menu-pill"
            v-bind:class="chatId === 'resolved' ? 'active' : ''"
            @click="changeChat('resolved')"
          >
            Resolved
          </div>
        </div>
      </div>
    </div>
    <div class="h-100 mx-0">
      <div class="mx-0 row h-100 pb-4">
        <div class="col-4 px-0 help-chat__rooms">
          <template v-if="fetchingMessages">
            <div class="overlay">
              <div class="d-flex">
                <b-spinner variant="danger"></b-spinner>
                <span class="ml-4">Fetching...</span>
              </div>
            </div>
          </template>
          <template v-else>
            <ul class="help-chat__rooms__list">
              <template
                v-if="rooms.length > 0 && Object.keys(users).length > 0"
              >
                <template v-for="(room, index) in rooms">
                  <template v-if="room">
                    <li
                      @click="setActive(index)"
                      :key="room.key"
                      :class="
                        activeRoom && activeRoom.id === room.id
                          ? 'help-chat__rooms__list__item--active'
                          : ''
                      "
                    >
                      <div class="help-chat__rooms__user">
                        <div class="help-chat__rooms__user__image">
                          <img src="/media/users/user1.jpg" alt="User Image" />
                        </div>
                        <div class="help-chat__rooms__user__name">
                          {{ users[room.members[0]].username }}
                          <br />
                          <small>{{
                            users[room.members[0]].email || ""
                          }}</small>
                          <br />
                          <small>{{
                            users[room.members[0]].phone || ""
                          }}</small>
                        </div>
                        <span
                          v-if="!room.is_seen && room.lastSender !== userId"
                          class="ml-auto notification-red"
                        ></span>
                      </div>
                    </li>
                  </template>
                </template>
              </template>
              <template v-else>
                <li class="help-chat__rooms__list__item--active">
                  No {{ chatId }} chats
                </li>
              </template>
            </ul>
          </template>
        </div>
        <div class="col-8 px-0 help-chat__messages">
          <template v-if="activeRoom !== null">
            <div class="help-chat__messages__topbar">
              <template v-if="chatId === 'resolved'">
                <button class="btn btn-resolved" @click="archiveRoom">
                  <i class="fa fa-folder"></i> Archive
                </button>
              </template>
              <template v-if="chatId === 'resolved'">
                <button class="btn btn-resolved" @click="unresolveRoom">
                  <i class="fa fa-check"></i> Unresolve
                </button>
              </template>
              <template v-else>
                <button class="btn btn-resolved" @click="resolveRoom">
                  Move to Resolved
                </button>
              </template>
            </div>
          </template>
          <div class="help-chat__messages__container" id="messages-container">
            <template v-if="fetchingMessages">
              <div class="overlay">
                <div class="d-flex">
                  <b-spinner variant="danger"></b-spinner>
                </div>
              </div>
            </template>
            <template v-else>
              <template v-for="message in messages">
                <div
                  :class="`help-chat__message ${
                    message.sender === userId ? 'help-chat__message--sent' : ''
                  }`"
                  :key="message.key"
                >
                  <span
                    :class="`help-chat__message__avatar ${
                      message.sender === userId
                        ? 'help-chat__message__avatar--sent'
                        : ''
                    }`"
                  >
                    <!-- <progressive-img
                      src="https://unsplash.it/1920/1080?image=10"
                      placeholder="https://unsplash.it/1920/1080?image=10"
                      :blur="30"
                    /> -->
                    <!-- <img
                      :src="
                        ` ${
                          message.sender === userId
                            ? `/media/users/user1.jpg`
                            : `${
                                users[message.sender].imageURL != ''
                                  ? users[message.sender].imageURL
                                  : '/media/users/user1.jpg'
                              }`
                        }`
                      "
                      alt="user-img"
                    /> -->

                    <img
                      src="
                       
                           /media/users/user1.jpg 
                      "
                      alt="profile-img"
                    />
                  </span>
                  <div
                    :class="`help-chat__message__text ${
                      message.sender === userId
                        ? 'help-chat__message__text--sent'
                        : ''
                    }`"
                  >
                    <template v-if="message.attachments !== ''">
                      <img
                        style="
                          max-width: 250px;
                          width: 100%;
                          height: auto;
                          display: block;
                          margin-bottom: 5px;
                        "
                        :src="message.attachments"
                        alt="message attachment"
                      />
                      <!-- <progressive-img
                        style="
                        max-width:250px;
                        width:100%;
                        height:auto;
                      "
                        :src="message.attachments"
                        alt="message attachment"
                        :blur="30"
                      /> -->
                    </template>
                    <span>{{ message.text }}</span>
                    <span class="help-chat__message__stats">
                      {{ humanize(message.sentAt) }}
                    </span>
                  </div>
                </div>
                <div
                  :key="message.key"
                  v-if="message.resolved"
                  class="help-chat__messages__container--resolved"
                >
                  Resolved
                </div>
              </template>
              <div class="help-chat__message">
                <div
                  class="help-chat__message__text"
                  :style="sizeOf(messages) > 0 ? 'opacity:0' : 'opacity:1'"
                >
                  No Messages to show ...
                </div>
              </div>
            </template>
          </div>
          <div class="help-chat__messages__input-box">
            <form @submit.prevent="sendMessage">
              <b-input-group class="help-chat__messages__input-group">
                <b-input
                  type="text"
                  placeholder="Type Something"
                  v-model="newMessage"
                  :disabled="sendingMessage || chatId === 'resolved'"
                />
                <b-input-group-append>
                  <button
                    type="submit"
                    class="help-chat__messages__send"
                    :disabled="sendingMessage || chatId === 'resolved'"
                  >
                    <i class="fa fa-paper-plane"></i>
                  </button>
                </b-input-group-append>
              </b-input-group>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import fire from "@/core/plugins/firestore";
import moment from "moment";
import JwtService from "@/core/services/jwt.service";
import { mapState } from "vuex";
import ApiService from "@/core/services/api.service";
// import ProgressiveImage from "vue-progressive-image";
export default {
  name: "Chat",
  // components: {
  //   ProgressiveImage
  // },
  data() {
    return {
      newMessage: "",
      sendingMessage: false,
      rooms: [],
      users: {},
      userId: "",
      activeRoom: null,
      messages: [],
      chatId: this.$route.params.id,
      fetchingMessages: false,
      messageCollection: "",
      roomCollection: "",
      userCollection: "",
      allRooms: { customer: [], rider: [], vendor: [] },
      notifications: { customer: 0, rider: 0, vendor: 0 },
    };
  },
  mounted() {
    if (this.region === "US") {
      if (this.stage === "PROD") {
        this.messageCollection = "messages_us_prod";
        this.roomCollection = "rooms_us_prod";
        this.userCollection = "users_us_prod";
      } else {
        this.messageCollection = "messages_us_qa";
        this.roomCollection = "rooms_us_qa";
        this.userCollection = "users_us_qa";
      }
    } else if (this.region === "PAKISTAN") {
      if (this.stage === "PROD") {
        this.messageCollection = "messages_pk_prod";
        this.roomCollection = "rooms_pk_prod";
        this.userCollection = "users_pk_prod";
      } else {
        this.messageCollection = "messages_pk_qa";
        this.roomCollection = "rooms_pk_qa";
        this.userCollection = "users_pk_qa";
      }
    }
    this.userId = JwtService.getUserId();
    this.mountComponent();
    // this.readRooms();
  },
  computed: {
    ...mapState({
      region: (state) => state.region.isRegion,
      stage: (state) => state.region.isStage,
    }),
  },
  updated() {
    this.scrollToEnd();
  },
  methods: {
    sizeOf(obj) {
      let retobjectLenght = Object.keys(obj).length;
      return retobjectLenght;
    },
    changeChat(id) {
      if (this.chatId !== id && !this.fetchingMessages)
        this.$router.push({
          path: `/help-center/${id}`,
        });
    },

    async archiveRoom() {
      await fire
        .database()
        .ref(`${this.roomCollection}/${this.activeRoom.id}/archived`)
        .set(true)
        .then(async () => {
          const temp = this.activeRoom.id;
          this.rooms = this.rooms.filter((room) => room.id !== temp);
          this.messages = [];
          this.activeRoom = null;
          await this.mountComponent();
          // await this.readRooms();
        });
    },
    async unresolveRoom() {
      // await fire
      //   .database()
      //   .ref(`${this.roomCollection}/${this.activeRoom.id}/roomType`)
      //   .set(`${this.activeRoom.type}-support`)
      //   .then(async () => {
      //     const temp = this.activeRoom.id;
      //     this.rooms = this.rooms.filter(room => room.id !== temp);
      //     this.messages = [];
      //     this.activeRoom = null;
      //     await this.mountComponent();
      //     // await this.readRooms();
      //   });
    },
    async resolveRoom() {
      let index = null;
      await fire
        .database()
        .ref(`${this.messageCollection}/${this.activeRoom.messages}/messages`)
        .orderByKey()
        .limitToLast(1)
        .once("value", (snapshot) => {
          index = Object.keys(snapshot.val())[0];
        });
      const roomData = {
        ...this.activeRoom,
      };

      roomData.resolved = true;
      await fire
        .database()
        .ref(
          `${this.messageCollection}/${this.activeRoom.messages}/messages/${index}`
        )
        .set({
          ...this.messages[this.messages.length - 1],
          resolved: true,
        })
        .then(async () => {
          await fire
            .database()
            .ref(`${this.roomCollection}/${this.activeRoom.id}`)
            .set(roomData)
            .then(() => {
              const temp = this.activeRoom.id;
              this.rooms = this.rooms.filter((room) => room.id !== temp);
              this.messages = [];
              this.activeRoom = null;
              this.mountComponent();
              // this.readRooms();
            });
        });
    },
    async mountComponent() {
      this.fetchingMessages = true;
      // if (this.region === "PAKISTAN") {
      let userData = {
        id: this.userId,
        imageURL: "",
        username: "Super Admin",
        email: "admin@gmail.com",
        onlineTime: "",
        phone: "",
        search: "dashboard",
      };
      let email = "baskytrider440@gmail.com";
      let password = "baskyt112233";
      await fire.auth().signInWithEmailAndPassword(email, password);
      await fire
        .database()
        .ref(`${this.userCollection}/${userData.id}`)
        .set(userData);
      await fire
        .database()
        .ref(`${this.roomCollection}`)
        .orderByChild("lastUpdated")
        .on("value", (snapshot) => {
          if (snapshot.exists()) {
            let userData = snapshot.val();
            let userType = "";
            this.allRooms = { customer: [], rider: [], vendor: [] };
            this.notifications = { customer: 0, rider: 0, vendor: 0 };
            if (userData) {
              Object.keys(userData).forEach((key) => {
                userType = userData[key].roomType;
                userData[key].id = key;
                if (userType === "customer-support")
                  this.allRooms.customer.push(userData[key]);
                else if (userType === "rider-support")
                  this.allRooms.rider.push(userData[key]);
                else if (userType === "vendor-support")
                  this.allRooms.vendor.push(userData[key]);

                if (
                  userData[key].is_seen === false &&
                  userData[key].lastSender !== this.userId
                ) {
                  if (userData[key].roomType === "customer-support")
                    this.notifications.customer += 1;
                  else if (userData[key].roomType === "vendor-support")
                    this.notifications.vendor += 1;
                  else if (userData[key].roomType === "rider-support")
                    this.notifications.rider += 1;
                }
              });
            }

            if (userType === "customer-support") {
              this.allRooms.customer = this.allRooms.customer.filter(
                (a) => !a.archived && !a.resolved
              );

              this.allRooms.customer = this.allRooms.customer.sort(
                (a, b) => b.lastUpdated - a.lastUpdated
              );
              // this.allRooms.customer = this.allRooms.customer;
            } else if (userType === "rider-support") {
              this.allRooms.rider = this.allRooms.rider.filter(
                (a) => !a.archived && !a.resolved
              );

              this.allRooms.rider = this.allRooms.rider.sort(
                (a, b) => b.lastUpdated - a.lastUpdated
              );
              // this.allRooms.rider = this.allRooms.rider;
            } else if (userType === "vendor-support") {
              this.allRooms.vendor = this.allRooms.vendor.filter(
                (a) => !a.archived && !a.resolved
              );

              this.allRooms.vendor = this.allRooms.vendor.sort(
                (a, b) => b.lastUpdated - a.lastUpdated
              );
              // this.allRooms.vendor = this.allRooms.vendor;
            }
            this.readRooms();
          }
        });
      // await fire
      //   .database()
      //   .ref(`${this.roomCollection}`)
      //   .orderByChild("is_seen")
      //   .on("value", snapshot => {
      //     this.notifications = { customer: 0, rider: 0, vendor: 0 };
      //     let userData = snapshot.val();
      //     Object.keys(userData).forEach(key => {
      //       if (
      //         userData[key].is_seen === false &&
      //         userData[key].lastSender !== this.userId
      //       ) {
      //         if (userData[key].roomType === "customer-support")
      //           this.notifications.customer += 1;
      //         else if (userData[key].roomType === "vendor-support")
      //           this.notifications.vendor += 1;
      //         else if (userData[key].roomType === "rider-support")
      //           this.notifications.rider += 1;
      //       }
      //     });
      //   });
    },
    async readRooms() {
      if (this.chatId !== "resolved") {
        if (this.chatId === "customer") {
          this.rooms = this.allRooms.customer;
          this.rooms = this.rooms.filter((a) => !a.archived && !a.resolved);

          this.rooms = this.rooms.sort((a, b) => b.lastUpdated - a.lastUpdated);
        }
        if (this.chatId === "rider") {
          this.rooms = this.allRooms.rider;
          this.rooms = this.rooms.filter((a) => !a.archived && !a.resolved);

          this.rooms = this.rooms.sort((a, b) => b.lastUpdated - a.lastUpdated);
        }
        if (this.chatId === "vendor") {
          this.rooms = this.allRooms.vendor;
          this.rooms = this.rooms.filter((a) => !a.archived && !a.resolved);

          this.rooms = this.rooms.sort((a, b) => b.lastUpdated - a.lastUpdated);
        }
      } else {
        this.rooms = [
          ...this.allRooms.customer,
          ...this.allRooms.rider,
          ...this.allRooms.vendor,
        ];
      }

      if (this.rooms.length > 0) {
        // const uniqueUsers = this.rooms.reduce((acc, data) => {
        //   data.members.forEach(item => {
        //     if (!acc.includes(item)) acc.push(item);
        //   });
        //   return acc;
        // }, []);
        // //
        // if (!uniqueUsers.includes(this.userId)) {
        //   uniqueUsers.push(this.userId);
        // }
        // this.users = {};
        await Promise.all(
          this.rooms.map(async (user) => {
            await fire
              .database()
              .ref(`${this.userCollection}/${user.members}`)
              .once("value", (snapshot) => {
                if (snapshot.exists()) {
                  let user = snapshot.val();

                  this.users[user.id] = user;
                }
              });
          })
        );
      }

      this.fetchingMessages = false;
    },
    humanize(date) {
      const humanDate = moment(date).format("MMMM Do YYYY, h:mm a");
      return humanDate;
    },
    scrollToEnd() {
      var container = this.$el.querySelector("#messages-container");
      container.scrollTop = container.scrollHeight;
      container.scrollIntoView({ behavior: "smooth" });
    },
    async sendMessage() {
      if (this.newMessage === "" || this.activeRoom === null) return;
      // this.sendingMessage = true;
      const time = Date.now();
      // Get the current room
      // Create the message object
      const message = {
        attachments: "",
        text: this.newMessage,
        sender: this.userId,
        sentAt: time,
      };
      this.newMessage = "";

      // Push the message
      await fire
        .database()
        .ref(`${this.messageCollection}/${this.activeRoom.messages}/messages`)
        .push(message)
        .then(async () => {
          const roomData = {
            ...this.activeRoom,
          };
          roomData.lastMessage = message.text;
          roomData.lastSender = message.sender;
          roomData.lastUpdated = message.sentAt;
          roomData.resolved = false;
          roomData.is_seen = false;
          await fire
            .database()
            .ref(`${this.roomCollection}/${this.activeRoom.id}`)
            .set(roomData);
        });
      await ApiService.post("/slack/message", {
        messageType: "text",
        sender: message.sender,
        message: message.text,
        token: JwtService.getToken,
      });
      this.sendingMessage = false;
    },
    async setActive(index) {
      if (this.activeRoom === this.rooms[index]) return;
      this.activeRoom = this.rooms[index];
      if (this.chatId !== "resolved") {
        const roomData = {
          ...this.activeRoom,
        };
        roomData.is_seen = true;
        if (this.activeRoom.lastSender !== this.userId)
          await fire
            .database()
            .ref(`${this.roomCollection}/${this.activeRoom.id}`)
            .set(roomData);
        await fire
          .database()
          .ref(`${this.messageCollection}/${this.activeRoom.messages}/messages`)
          .on("value", (snapshot) => {
            // this.messages = snapshot.val();
            let temp = [];
            snapshot.forEach((doc) => {
              let item = doc.val();
              if (item.resolved) temp = [];
              else temp.push(item);
            });
            this.messages = temp;
          });
      } else {
        await fire
          .database()
          .ref(`${this.messageCollection}/${this.activeRoom.messages}/messages`)
          .on("value", (snapshot) => {
            let resolved = 0;
            const temp = [];
            let dump = false;
            let patch = [];
            snapshot.forEach((data) => {
              if (data.val().resolved && (resolved === 1 || resolved === 0)) {
                // this.messages = [];
                let item = data.val();
                patch.push(item);
                resolved = 2;
                dump = true;
                temp.push(...patch);
                console.log(
                  "🚀 ~ file: chat.vue ~ line 671 ~ setActive ~ temp",
                  patch
                );
                patch = [];
              }
              if (resolved === 0) {
                let item = data.val();
                patch.push(item);
              } else if (resolved === 2) {
                resolved = 1;
              } else {
                let item = data.val();
                patch.push(item);
                resolved = 0;
              }
            });
            dump ? (this.messages = temp) : (this.messages = []);
            // this.messages = temp;
          });
      }
    },
  },
  watch: {
    $route(to) {
      const id = to.params.id;
      if (id !== this.chatId) {
        this.rooms = [];
        this.messages = [];
        this.activeRoom = null;
        this.chatId = id;
        this.mountComponent();
        // this.readRooms();
      }
    },
  },
};
</script>
<style lang="scss">
.help-chat {
  border-radius: 5px;
  max-height: 600px;
  min-height: 450px;
  height: 100%;
  // border: 2px solid #ccc;
  &__rooms {
    height: 100%;
    border: 2px solid rgb(220, 24, 40);
    border-right: 1px solid rgb(220, 24, 40);
    border-radius: 5px 0 0 5px;
    overflow: auto;
    background: #fff;
    &__list {
      list-style: none;
      li {
        cursor: pointer;
        transition: all 0.25s;
        // box-shadow: 0px 1px 1.5px 0.5px rgba(0, 0, 0, 0.25);
        width: 100%;
        padding: 20px 10px;
        border-bottom: 1px solid rgb(220, 24, 40);
        &:hover {
          background: rgba(220, 24, 40, 0.75);
          color: #fff;
        }
      }
      &__item--active {
        background: rgba(220, 23, 40, 1);
        color: #fff;
        &:hover {
          background: rgba(220, 24, 40, 1) !important;
        }
      }
    }
    &__user {
      display: flex;
      align-items: center;
      &__image {
        border-radius: 100%;
        border: 1px solid #ccc;
        background: #fff;
        overflow: hidden;
        img {
          width: 40px;
          height: 40px;
        }
      }
      &__name {
        padding: 5px 10px;
        font-weight: 600;
      }
    }
  }
  &__messages {
    height: 100%;
    position: relative;
    transition: all 0.5s;
    border: 2px solid rgb(220, 24, 40);
    border-radius: 0 5px 5px 0;
    border-left: 1px solid rgb(220, 24, 40);
    border-bottom: 0;
    background: #fff;
    // box-shadow: 2px 3px 5px 0.5px rgba(0, 0, 0, 0.25);
    &__topbar {
      padding: 5px;
      display: flex;
      justify-content: flex-end;
      border-bottom: 1px solid rgb(220, 24, 40);
      .btn-resolved {
        margin-left: 5px;
        color: #fff;
        background: rgba(220, 24, 40, 1);
        transition: all 0.25s;
        &:hover {
          color: rgb(220, 24, 40);
          background: #fff;
          border: 1px solid rgb(220, 24, 40);
          i {
            color: rgb(220, 24, 40);
          }
        }
        &:active {
          transform: translate(1px, 1px);
        }
        i {
          color: #fff;
        }
      }
    }
    &__container {
      transition: all 0.5s;
      overflow: scroll;
      overflow-x: hidden;
      height: 500px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-bottom: 40px;
      &--resolved {
        width: 100%;
        background: #af5757;
        color: white;
        padding: 5px;
        text-align: center;
        font-size: 15px;
      }
    }
    &__input-group {
      display: grid;
      grid-template-columns: 1fr auto;
      border: 2px solid rgba(220, 24, 40, 1);
      border-radius: 0 5px 5px 0;
      transform: scale(1.005);
      background: rgba(255, 255, 255, 0.5);
      input {
        padding: 5px;
        height: 100%;
        outline: none;
        width: 100% !important;
        border: 0;
      }
    }
    &__input-box {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    &__send {
      padding: 5px 10px;
      background: rgba(220, 24, 40, 1);
      &--disabled,
      &:hover {
        background: rgba(220, 24, 40, 0.85);
      }
      i {
        color: #fff;
      }
    }
  }

  .notification-red {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 10px;
    background: red;
  }
  .notification-white {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: white;
    color: #dc1828;
    padding-bottom: 5px;
    text-align: center;
  }
  &__message {
    transition: all 0.5s;
    margin: 5px;
    margin-right: auto;
    display: flex;
    animation: pop-up 0.75s;
    &--sent {
      margin-right: 5px;
    }
    &__text {
      width: 100%;
      display: block;
      padding: 10px;
      // margin: 5px 0px;
      margin: 5px 10px;
      order: 2;
      // box-shadow: 1px 2px 2px 0.5px rgba(0, 0, 0, 0.15);
      color: #000;
      border-radius: 5px 5px 20px 0px;
      background: #ccc;
      &--sent {
        color: #fff;
        order: 1;
        // box-shadow: -2px 3px 5px 0.5px rgba(0, 0, 0, 0.25);
        border-radius: 5px 5px 0px 20px;
        background: #dc1828;
      }
    }
    &__stats {
      display: block;
      font-size: 10px;
      margin-right: auto;
      text-align: right;
    }
    &__avatar {
      &--sent {
        order: 2;
      }
      order: 1;
      display: grid;
      place-items: end;
      padding-bottom: 5px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 100%;
      }
    }
    &:last-child {
      margin-bottom: 20px;
    }
  }
  @keyframes pop-up {
    from {
      transform: translateY(20px);
    }
    to {
      transform: translateY(0px);
    }
  }
  .top-row {
    z-index: 96;
    display: flex;
    position: relative;
    justify-content: center;
    .heading-text {
      position: absolute;
      left: 15px;
      bottom: 10px;
    }
    .menu-pill-container {
      border-radius: 50px;
      overflow: hidden;
      .menu-pill {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 15px;
        width: 150px;
        &.active {
          background: #dc1828;
          color: #fff;
        }
        color: #000;
      }
    }
  }
  .overlay {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    display: flex;
    justify-content: center;
    padding: 25px;
    top: 0;
    left: 0;
    z-index: 1;
  }
}
</style>
